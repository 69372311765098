<template>
    <div class="bg-lg h-full">
        <div class="p-5 sm:p-10 grid grid-cols-1">
            <span class="sm:hidden col-span-1 font-semibold text-lg text-tex-3 sm:text-black ffamily-pro">{{ $t("homeDash.titleBtn") }}</span>

            <router-link to="counters"><a class="hidden sm:flex col-span-1 font-semibold text-lg text-white ffamily-pro" href=""><Iconchevroncir class="mr-1"/>{{ $t("meterSeen.return") }}</a></router-link>
            <div v-if="counterPurchases.length != 0" class=" col-span-1 grid lg:grid-cols-3 lg:gap-12 gap-4 sm:mb-12 mt-6">
                <div class="ffamily-pro bg-white col-span-1 flex items-center rounded-lg shadow p-2" style="text-align: left">
                    <span>
                        <img class="m-2" src="@/assets/images/compteur mini.png" alt="">
                    </span>
                    <div class="flex flex-col ">
                        <span class="text-xl font-semibold">{{ meterPurchase.counter }}</span>
                        <span class="text-sm font-semibold mb-1">{{ meterPurchase.cUser }} ({{ meterPurchase.cFamily }}) <button class="focus:outline-none text-radio-100 underline" @click="editMeter(meterPurchase.counter, meterPurchase.counterId)">{{ $t("meterSeen.modify") }}</button></span>
                        <button class="focus:outline-none text-white text-sm font-semibold py-1 rounded flex justify-center items-center" style="background:#F11C33" @click="rechargeMeter(meterPurchase.counter,  meterPurchase.cUser)">{{ $t("meterSeen.recharg") }}</button>
                    </div>
                </div>

                <div class=" bg-white col-span-1 flex items-center  rounded-lg shadow p-2" style="text-align: left">
                    <span class="m-2"><img src="../../assets/svg/dashbIcons/dollar.svg" alt=""></span>
                    <div class="flex flex-col "  style="color: #222F5A;">
                        <span class="font-bold ">{{ $t("meterSeen.total") }}</span>
                        <span class="text-4xl text-tex-2 font-semibold ffamily-pro">{{ meterTotalRecharge }} FCFA</span>
                    </div>
                </div>

                <div class="bg-white  col-span-1 flex items-center  rounded-lg shadow p-2" style="text-align: left">
                    <span><Iconcalb class="m-2"/></span>
                    <div class="flex flex-col "  style="color: #222F5A;">
                        <span class="font-bold ">{{ $t("meterSeen.date") }}</span>
                        <span class="text-3xl font-semibold ffamily-pro">{{ meterDate }}</span>
                    </div>
                </div>
            </div>

            <div v-if="counterPurchases.length != 0"  class="bg-white col-span-1">
                <table class="w-full  table-auto xl:table-fixed text-sm border border-solid ffamily-pro">
                    <thead class=" hidden lg:table-header-group font-bold text-left border border-bgk-2 border-solid">
                        <th class="p-2 sm:p-3 ">{{ $t("homeDash.meterNumb") }}</th>
                        <th>{{ $t("homeDash.metrAmount") }}</th>
                        <th>{{ $t("homeDash.date") }}</th>
                        <th>{{ $t("homeDash.action") }}</th>
                    </thead>
                    <tbody>
                        <tr class="hover:bg-bgk-1 border border-bgk-2 border-solid" v-for="purchase in counterPurchases" :key="purchase.id" >
                            <td class="flex flex-col p-3 ">
                                <span class="text-sm">{{ purchase.counter }}</span>
                                <span class="text-xs" style="color: #4D4C4C">{{ purchase.cUser }}</span>
                            </td>
                            <td class="text-right lg:text-left">
                                <span>{{ purchase.amount }} Fcfa</span><br>
                                <span class="lg:hidden">{{ purchase.date }}</span>
                            </td>
                            
                            <td >
                                <span class="hidden lg:table-cell">{{ purchase.date }}</span>
                            </td>
                            <td class="px-2 lg:px-0 w-8 lg:w-auto">
                                <div class="lg:flex hidden">
                                    <div class="flex items-center">
                                        <button class="focus:outline-none mr-2" :title="$t('tooltip.downloadbill')" @click="downloadBill(purchase.transacId)"><Icondownl/></button>
                                        <button class="focus:outline-none mr-2" @click="passSendPurchase(purchase.transacId)"><Iconsend/></button>
                                        <!-- <button class="focus:outline-none mr-2" @click="passDeletePurchase(purchase.id)"><Icontrash/></button> -->
                                    </div>
                                    <div class="border-l-2">
                                        <button class="focus:outline-none flex items-center ml-2" :title="$t('tooltip.sendbill')" @click="renouveller(purchase.counter, purchase.cUser)">
                                            <Iconrefresh/>
                                            <span class="text-sm hidden lg:block font-semibold ml-2" style="color: #0965F6;">{{ $t("homeDash.actionRefresh") }}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="lg:hidden">
                                    <Icondatatabl @click="purchase.active = !purchase.active" class=""/>
                                    <div v-show=" purchase.active == true" class="absolute -ml-24  bg-white flex flex-col p-4 shadow-xs rounded" @click="purchase.active = !purchase.active">
                                        <button class="focus:outline-none flex items-center mb-1" @click="downloadBill(purchase.transacId)"><Icondownl class="mr-3"/><span>{{ $t("homeDash.actionDown") }}</span></button>
                                        <button class="focus:outline-none flex items-center mb-1" @click="passSendPurchase(purchase.transacId)"><Iconsend class="mr-3"/><span>{{ $t("homeDash.actionSend") }}</span></button>
                                        <!-- <button class="focus:outline-none flex items-center mb-1" @click="passDeletePurchase(purchase.id)"><Icontrash class="mr-3"/><span>{{ $t("homeDash.actionTrash") }}</span></button> -->
                                        <button class="focus:outline-none flex items-center" @click="renouveller(purchase.counter, purchase.cUser)"><Iconrefresh class="mr-3"/><span>{{ $t("homeDash.actionRefresh") }}</span></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody> 
                </table>
                <div v-show="pages.length > 1" class="p-4 text-right border  border-bgk-2">
                    <nav>
                        <ul>
                            <li>
                                <button :class="currentPage === pageNumber ? 'bg-red-600 text-white' : 'bg-white' "
                                    class="focus:outline-none  text-center text-xs rounded-full h-5 w-5 " v-for="pageNumber in pages"
                                    :key="pageNumber.id" @click="gotoPage(pageNumber)">{{pageNumber}}</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div v-else class="bg-white rounded h-statedt">
                <div class="mx-auto" style="height: 300px; width:300px"><img src="@/assets/gifts/dataTableEmpty.gif" alt=""></div>
                <div class="mt-16 text-xl text-center">{{ $t("meterSeen.datatableEmpty") }}</div>
            </div>
        </div>
        <KdEditCounterModal/>
        <KdSendModal />
    </div>
</template>

<script>
import KdEditCounterModal from './kdEditCounterModal'
import KdSendModal from "./kdSendModal";
import axios from 'axios'
import Iconcalb from '../../assets/svg/dashbIcons/calendarbig.svg?inline'
import Icondownl from '../../assets/svg/dashbIcons/downloadblue.svg?inline'
import Iconsend from '../../assets/svg/dashbIcons/sendgreen.svg?inline'
import Iconrefresh from '../../assets/svg/dashbIcons/refresh.svg?inline'
import Icondatatabl from '../../assets/svg/dashbIcons/datatablmb.svg?inline'
import Iconchevroncir from '../../assets/svg/dashbIcons/chevroncircle.svg?inline'
export default {
    components: {
        Iconcalb,
        Icondownl,
        Iconsend,
        KdEditCounterModal,
        Iconrefresh,
        Icondatatabl,
        Iconchevroncir,
        KdSendModal
    },
    props: {
        trashModOpen:{
            type: Boolean,
            default: false
        },
        sendModOpen:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            calendar: false,
            chevron: true,
            page: 1,
            pages: [],   
        }   
    },
    methods:{
        editMeter(meterNumb, id){
            this.$store.commit('mutEditCounter',true)
            const meter = {
                numb: meterNumb,
                id: id
            }
            this.$store.commit('mutMeterToEdit',meter)
        },
        rechargeMeter(numb, user){
            const meter = {
                cNumb: numb,
                cUser: user
            }
            this.$store.commit('mutSelectedMeter', meter)
            this.$store.commit('mutCheckArrear')
            this.$router.push("/dashboard/recharge");
        },
        downloadBill(transacId){
            axios.post(`bills/download/${transacId}`)
            .then(res=>{
                window.open(res.data, '_blank');
            })
        },
        renouveller(numb, user){
            const meter = {
                cNumb: numb,
                cUser: user
            }
            this.$store.commit('mutSelectedMeter', meter)
            this.$store.commit('mutCheckArrear')
            this.$router.push("/dashboard/recharge");
        },
        calendarActive(){
             this.calendar = !this.calendar
        },
        setPages () {
            this.pages = []
            let numberOfpages = Math.ceil(this.numbOfPages);
            for (let index = 1; index <= numberOfpages; index++) {
                this.pages.push(index);
            }
        },
        gotoPage(pageNumber){
            this.page = pageNumber;
            let meter = {
                id: this.meterPurchase.counterId,
                page: pageNumber
            }
            this.$store.commit("mutSeeCounterPurchases",meter)
        },
        passDeletePurchase(x){
            this.$store.commit('mutPurchaseId', x);
            this.$store.commit('mutTrashPurchase', true);
        },
        passSendPurchase(id){
            this.$store.commit('mutSendPurchase', true);
            this.$store.commit("mutTransacId", id)
        }
    },
    computed: {
        counterPurchases(){
            return this.$store.getters.getCounterPurchases
        },
        currentPage(){
            return this.page;
        },
        numbOfPages(){
            return this.$store.getters.getMeterNumbOfPages;
        },
        meterTotalRecharge(){
            return this.$store.getters.getMeterTotalRecharge
        },
        meterPurchase(){
            const purchases = this.$store.getters.getCounterPurchases
            const purchase = purchases[0]
            return purchase
        },
        meterDate(){
            return this.$store.getters.getCounterToSeenCreatedDate
        }
    },
    watch: {
        numbOfPages () {
            this.setPages();
        }
    },
    mounted(){
        this.counterPurchases;
        this.setPages();
    },
    
}
</script>

<style scoped>
 .bg-lg {
    background-image: url(../../assets/svg/SBEEA1.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
}


@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/dashbIcons/background2lg.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
  }
}

</style>