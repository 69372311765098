<template>
  <div
    v-if="sendPurchase"
    class="anime transform top-0 left-0 w-full h-full fixed"
    :class="sendPurchase ? 'translate-x-0' : '-translate-x-full'"
  >
    <div class="container mx-auto bg-white rounded w-10/12 sm:w-6/12 mt-12">
      <span class="flex justify-end p-2 pb-1">
        <Iconclose @click="passsendPurchase" />
      </span>
      <div class="p-5 pt-0">
        <div class="">
          <span
            class="text-xl sm:text-2xl font-semibold"
            style="color: #201f1e"
          >
            {{ $t("billTrash.sendBill") }}
          </span>
        </div>
        <form @submit.prevent="sendBill">
          <div class="py-4">
            <div class="bg-gray-300 inline-flex" style="border-radius: 1rem">
              <button
                @click="billSendMethod"
                type="button"
                :class="
                  mail
                    ? 'bg-white transition-all duration-500 transform -translate-x-1'
                    : ''
                "
                class="
                  focus:outline-none
                  m-2
                  text-gray-800
                  font-bold
                  py-2
                  px-4
                  w-1/2
                  flex
                  justify-center
                "
                style="border-radius: 0.75rem"
              >
                <Iconsmail />
                <span class="ml-1 text-xs">Email</span>
              </button>
              <button
                @click="billSendMethod"
                type="button"
                :class="
                  !mail
                    ? 'bg-white transition-all duration-500 transform translate-x-1'
                    : ''
                "
                class="
                  focus:outline-none
                  m-2
                  text-gray-800
                  font-bold
                  py-2
                  px-4
                  w-1/2
                  flex
                  justify-center
                "
                style="border-radius: 0.75rem"
              >
                <Iconssms />
                <span class="ml-1 text-xs">SMS</span>
              </button>
            </div>
            <div class="mt-4" v-if="mail">
              <label class="text-base" for="mail">Email</label>
              <br />
              <div
                class="
                  bg-white
                  h-minput
                  border-1.5 border-kborder-500
                  hover:border-kinput-3
                  focus-within:border-kinput-2
                  rounded
                  flex flex-row
                  items-center
                "
              >
                <span class="p-2"><Iconmail /></span>
                <input
                  class="leading-tight w-full focus:outline-none focus:bg-white"
                  id="mail"
                  name="mail"
                  type="email"
                  v-validate="'required|email'"
                  v-model="channel.email"
                />
              </div>
              <span class="text-tex-2" v-if="errors.has('mail')"
                >Le champ email n'est pas valide</span
              >
            </div>
            <div class="mt-4" v-else>
              <label class="text-base" for="phone">{{
                $t("rechargeSuccess.phone")
              }}</label>
              <br />
              <div>
                <vue-tel-input
                  inputClasses=""
                  placeholder=""
                  name="phone"
                  inputId="phone"
                  :enabledCountryCode="true"
                  :validCharactersOnly="true"
                  @country-changed="countryChanged"
                  wrapperClasses="h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                  v-model="channel.smsTel"
                >
                </vue-tel-input>
                <div v-if="phoneError" class="flex items-center mt-1">
                  <IconError /><span class="ml-1 text-tex-2">{{
                    $t("sign.errorNumber")
                  }}</span>
                </div>
              </div>
            </div>

            <span class="flex items-center mt-4 w-full md:w-2/5">
              <base-button
                type="submit"
                color="secondary"
                :loading="loading"
                :text="$t('homeDash.actionSend')"
                full
              />
              <base-button
                :text="$t('homeDash.cancelBtn')"
                color="secondary"
                class="ml-4"
                outline
                full
                @click="passsendPurchase"
              />
              <!-- <button type="submit" class=" text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none" @click="sendBill">{{ $t("homeDash.actionSend") }}</button>
                            <button type="button" class="ml-4 border text-kbutton-1 border-kbutton-1 h-search sm:h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none"  @click="passsendPurchase">{{ $t("homeDash.cancelBtn") }}</button> -->
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Iconclose from "../../assets/svg/dashbIcons/close.svg?inline";
import Iconmail from "../../assets/svg/icons/mail.svg?inline";
import Iconsmail from "../../assets/svg/icons/smallmail.svg?inline";
import Iconssms from "../../assets/svg/icons/smallsms.svg?inline";
import { VueTelInput } from "vue-tel-input";
import IconError from "@/assets/svg/icons/errorIcon.svg?inline";
import BaseButton from "../../components/BaseButton.vue";
export default {
  components: {
    Iconclose,
    Iconmail,
    Iconsmail,
    Iconssms,
    VueTelInput,
    IconError,
    BaseButton,
  },
  props: ["isPost"],
  data() {
    return {
      sendMod: this.sendPurchase,
      mail: true,
      loading: false,
      channel: {
        email: null,
        smsTel: null,
        countryCode: null,
      },
      phoneError: false,
      phoneErrorMessage: "Le champ téléphone est invalid",
    };
  },
  computed: {
    sendPurchase() {
      return this.$store.getters.getSendPurchase;
    },
  },
  methods: {
    countryChanged(country) {
      this.channel.countryCode = country.dialCode;
    },
    sendBill() {
      if (this.isPost) {
        this.$emit("sent", this.channel);
        this.channel.smsTel = "";
        this.channel.email = "";
        this.passsendPurchase();
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (this.mail) {
          if (result) {
            this.$store.commit("mutByMail", true);
            // this.$store.commit("mutSendBill", this.channel)
            this.loading = true;
            this.$store
              .dispatch("sendBillRequest", this.channel)
              .then((res) => {
                this.$emit("success", res);
                this.passsendPurchase();
              })
              .catch((err) => {
                this.$emit("fail", err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            console.log("validate fail ===>");
            console.log("errors", this.$errors.first("mail"));
          }
        } else {
          this.channel.smsTel = this.channel.smsTel.replace(/\s+/g, "");
          if (this.channel.smsTel.length >= 8) {
            this.$store.commit("mutByMail", false);
            // this.$store.commit("mutSendBill", this.channel)
            this.loading = true;
            this.$store
              .dispatch("sendBillRequest", this.channel)
              .then((res) => {
                this.passsendPurchase();
                this.$emit("success", res);
              })
              .catch((err) => {
                this.$emit("fail", err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.phoneError = true;
          }
          this.phoneError = true;
        }
      });
    },
    passsendPurchase() {
      this.$store.commit("mutSendPurchase", false);
    },
    billSendMethod() {
      this.mail = !this.mail;
    },
  },
  watch: {
    sendPurchase: {
      immediate: true,
      handler(sendPurchase) {
        if (process.client) {
          if (sendPurchase)
            document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      },
    },
  },
};
</script>

<style scoped>
.anime {
  animation: modal 400ms ease-out forwards;
}
@keyframes modal {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.15);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border: 1px solid #c6d6ec;
  text-align: left;
}

.vue-tel-input:hover {
  border-color: #a5bce0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #4661b9;
}

.vue-tel-input:focus {
  outline: none !important;
  border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within {
  outline-style: none;
  border-top-left-radius: 0.125rem /* 2px */;
  border-bottom-left-radius: 0.125rem /* 2px */;
}
</style>
