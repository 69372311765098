<template>
    <div v-if="editCounter" class=" anime transform top-0 left-0 w-full h-full fixed "
        :class="editCounter ? 'translate-x-0' : '-translate-x-full'">
        <div class="container mx-auto bg-white rounded w-10/12 sm:w-6/12 mt-12">
            <span class="flex justify-end p-3 pb-1"><Iconclose @click="passEditCounter"/></span>
            <div class="pt-0 p-6">
                <div class="">
                    <span class="text-lg md:text-xl lg:text-2xl font-semibold" style="color: #201F1E;">{{ $t("meterDash.editTitle") }}</span>
                </div>

                <form @submit.prevent="meterValidate" class="py-4" v-if="!valider">
                    <div class="text-sm py-4">
                        <label class="font-semibold sm:font-normal text-sm"
                            for="compId">{{ $t("meterDash.counterNumb") }}</label>
                        <br>
                        <input placeholder="Entrez votre numero de compteur"
                            class="mt-2 p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white "
                            id="compId" name="compId" type="text" maxlength="20" 
                            v-model="counter.cNumb"
                            v-validate="'required|numeric|max:20'"
                            :class="{ ' border-tex-2': submitted && errors.has('compId') }">

                    <span v-show="submitted && errors.has('compId')" class="text-tex-2">{{ $t("counterRecharge.validate") }}</span>
                    <br><i v-show="invalidMeter" class="text-tex-2">{{ $t("counterRecharge.invalidMeter") }}</i>
                    </div>

                    <span class="mt-2 flex items-center ">
                        <button type="submit"
                            class=" text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none"
                        >
                            <LoaderCss class="ml-16" v-show="validRequest" />
                            <span v-show="!validRequest">{{ $t("counterRecharge.btn") }}</span>
                        </button>
                        <button type="button"
                            class="ml-4 border text-kbutton-1 border-kbutton-1 h-search sm:h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none"
                            @click="passEditCounter">{{ $t("homeDash.cancelBtn") }}</button>
                    </span>
                </form>

                <form @submit.prevent="editMeter" class="py-4" v-if="valider">
                    <div class="bg-gray-100 rounded border">
                        <div class=" m-3 p-1 flex flex-col">
                            <span class="text-base">{{ counter.cUser }}</span>
                            <div class="flex flex-row justify-between items-end">
                                <div>
                                    <span class="text-xs text-gray-500">{{ $t("meterDash.counterNumb") }}</span><br>
                                    <span>{{ counter.cNumb }}</span>
                                </div>
                                <button type="button"
                                    class="focus:outline-none bg-white text-compteur-600 hover:text-compteur-7 py-1 px-2 border border-compteur-600 hover:border-compteur-7 rounded "
                                    @click="valider = !valider">{{ $t("rechargePay.btnModify") }}</button>
                            </div>
                        </div>
                    </div>

                    <div class="text-sm py-4">
                        <label class="font-semibold sm:font-normal text-sm"
                            for="family">{{ $t("meterDash.family") }}</label>
                        <br>
                        <input placeholder="Ex: Maison"
                            class="mt-2 p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white "
                            id="family" name="family" type="text" maxlength="20"
                            v-model="counter.cFamily"
                            v-validate="'required|max:20'"
                            :class="{ ' border-tex-2': submittedAll && errors.has('family') }">
                    </div>

                    <span class="mt-2 flex items-center ">
                        <button type="submit"
                            class=" text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none"
                        >
                            <LoaderCss class="ml-16" v-show="addRequest" />
                            <span v-show="!addRequest">{{ $t("meterTrash.add") }}</span>
                        </button>
                        <button type="button"
                            class="ml-4 border text-kbutton-1 border-kbutton-1 h-search sm:h-minput  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base rounded focus:outline-none"
                            @click="passEditCounter">{{ $t("homeDash.cancelBtn") }}</button>
                    </span>
                </form>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Iconclose from '../../assets/svg/dashbIcons/close.svg?inline';
import LoaderCss from '@/components/LoaderCss'
export default {
    components:{
        Iconclose,
        LoaderCss
    },
    
    data() {
        return {
            invalidMeter: false,
            valider: false,
            submitted: false,
            submittedAll: false,
            counter: {
                cNumb: this.meterToEditNumb,
                cUser: '',
                cFamily: '',
                active: false
            },
            validRequest: false,
            addRequest: false,
        }
    },
    computed: {
        editCounter(){
            return this.$store.getters.getEditCounter
        },
        meterToEdit(){
            return this.$store.getters.getMeterToEdit
        },
        meterToEditNumb(){
            return this.$store.getters.getMeterToEdit.numb
        }
    },
    
    methods: {
        meterValidate(){
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
                this.validRequest = true;
                axios.post('/transaction', {
                    meterNumb: this.counter.cNumb,
                    amount: '1'
                })
                .then(res=>{
                    this.validRequest = false;
                    const data = res.data
                    const user = data.customerName
                    this.counter.cUser = user
                    this.valider = !this.valider
                })
                .catch(err=>{
                    this.validRequest = false;
                    console.log(err.response.data.message)
                    this.invalidMeter = true
                })
            }
            });
        },
        passEditCounter(){
            this.$store.commit('mutEditCounter',false)
            this.valider = false
        },

        editMeter(){
            this.submittedAll = true;
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.addRequest = true;
                    const editMeter = {
                        id: this.meterToEdit.id,
                        number: this.counter.cNumb,
                        user: this.counter.cUser,
                        familiar: this.counter.cFamily
                    }
                    this.$store.commit('mutCounterToEdit', editMeter)
                    this.passEditCounter()
                    this.addRequest = false;
                }
            })
        }
    },
    watch: {
    editCounter: {
      immediate: true,
      handler(editCounter) {
       if (process.client) {
          if (editCounter) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>